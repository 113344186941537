import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../services/global/global.service';
import { DateTime } from 'luxon';
import { timeout } from 'rxjs/operators';
import { AppsettingsService } from '../../../../services/app-settings/app-settings.service';
import jwt_decode from 'jwt-decode';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private HttpClient: HttpClient,
        public router: Router,
        private AppSettings: AppsettingsService,
        private GlobalService: GlobalService,
    ) { }

    httpOptions = {
        withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
    };

    async login(emailAddress: string, password: string | false) {
        let header = new HttpHeaders({ 'content-type': 'application/json' });

        let url = `${this.AppSettings.getApiServerUrlBase()}auth/login`;

        return await lastValueFrom<any>(
            this.HttpClient.post<any>(
                url,
                { header, emailAddress: emailAddress, password: password },
                { withCredentials: true }
            )
        );
    }

    // login(emailAddress: any, password: any) {
    //     return new Promise((resolve, reject) => {

    //         let header = new HttpHeaders({ "content-type": "application/json" });
    //         let url = this.AppSettings.getApiServerUrlBase() + 'auth/login';

    //         this.HttpClient.post(url, { header, emailAddress: emailAddress, password: password }, { withCredentials: true })
    //             .pipe(timeout(4000))
    //             .subscribe(
    //                 (data: any) => {
    //                     console.log('auth service', data)
    //                     // return resolve(this.GlobalService.httpCheckReturn(data));
    //                     return data
    //                 },
    //                 (err: any) => {
    //                     return resolve({ status: 'serverError' });
    //                 }
    //             );
    //     });
    // }

    saveAccessTokenLocalStorage(responseObj: any, redirect = true) {
        let accessToken: string = responseObj.accessToken;
        localStorage.setItem('accessToken', accessToken);

        if (redirect) {
            this.router.navigate(['']);
        }

        this.isLoggedIn();

    }

    getAccessToken() {

        const accessToken: any = this.sanitizeToken(localStorage.getItem('accessToken'));

        if (!accessToken) {
            return false;
        }

        return accessToken;
    }

    sanitizeToken(accessToken: any) {

        if (accessToken === null || accessToken === undefined || accessToken.length < 10) {
            return false;
        }

        return accessToken.replace(/(?!\.)(?!_)[^a-z,^A-Z,^0-9,^-]/g, '');
    }

    async logout() {

        console.log('Logging out!')
        localStorage.removeItem('accessToken');

        let url = this.AppSettings.getApiServerUrlBase() + 'auth/logout';
        this.HttpClient.get(url, this.httpOptions).subscribe( (data:any) => {

            // After we logged out and removed our cookie. Redirect to the login page.
            this.router.navigate(['auth/login', { autoLogin: false }]).then( () => {});

        });

    }

    isLoggedIn() {

        const accessToken = this.getAccessToken();

        if (!accessToken) {
            return false;
        }

        const timestampNow = +DateTime.utc();
        const expiration = this.getExpiration();
        const expirationMillis = +expiration * 1000;

        // Token is too old
        if (timestampNow > expirationMillis) {
            return false;
        }

        else {
            return true;
        }
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getExpiration() {

        const accessToken = this.getAccessToken();
        if (!accessToken) return false;

        const decoded: any = jwt_decode(accessToken);
        //iat, exp are of type INT
        const iat = decoded.iat;
        const exp = decoded.exp;

        return exp;

    }

    getUserID() {

        const accessToken = this.getAccessToken();

        if (!accessToken) {
            this.logout();
            return false;
        }

        const decoded: any = jwt_decode(accessToken);

        return decoded.userId;

    }

    authLoop() {

        if ( !this.isLoggedIn() ) {
            this.router.navigate(['auth/login']);
        }

        const timestampNow = +DateTime.utc();
        const accessTokenExp = this.getExpiration();
        const accessTokenExpirationMillis = +accessTokenExp * 1000;

        const difference = accessTokenExpirationMillis - timestampNow;

        // If the token expiry is less than 10 seconds, renew it!
        if (difference < 60000) {
            this.refreshAccessToken();
            return;
        }

        // Time target is 60 seconds before it expires.
        const timeTarget = accessTokenExpirationMillis - timestampNow - 60000;

        setTimeout(() => {
            this.refreshAccessToken();
        }, timeTarget);

    }

    refreshAccessToken() {
        this.refreshAccessTokenRequest().then( (data:any) => {

            if ( !data?.accessToken ) {
                console.log('No access token!')
                return
            }
            
            this.saveAccessTokenLocalStorage( data, false );
            this.authLoop();

        }).catch( (err:any) => {
            console.log(`Can't refresh access token!`)
            this.router.navigate(['auth/login', { autoLogin: false }])
        })
    }

    refreshAccessTokenRequest() {
		return new Promise((resolve, reject) => {
			const url = this.AppSettings.getApiServerUrlBase() + `auth/refresh`;
			this.HttpClient.get(url, this.httpOptions).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			});
		});
	}

    preLogin() {
        return new Promise((resolve, reject) => {
            let url = this.AppSettings.getApiServerUrlBase() + 'auth/pre-login';
            this.HttpClient.get(url, this.httpOptions).subscribe((res: any) => {
                return resolve(this.GlobalService.httpCheckReturn(res));
            });
        });
    }
    
}
